<template>
  <div style="width: 100vw; height: 100vh; overflow: hidden;">
    <iframe
      @message="postMessageFun"
			style="width: 100vw; height: 100vh;"
      :src="'/google-map.html?lat=' + lat + '&lng=' + lng"
    >
    </iframe>
  </div>
</template>

<script>
var msg = '';

export default {
  onReady() {},
  data() {
    return {
      mapAddress: '',
      mapsrc: '',
      name: '',
      country: '',
      province: '',
      city: '',
      address: '',
      lng: '',
      lat: ''
    };
  },
  created() {
    const option = this.$route.query;
    // this.lng = option.longitude;
    // this.lat = option.latitude;
  },
  mounted() {
    // let pages = getCurrentPages();  //获取所有页面栈实例列表
    // let prevPage = pages[ pages.length - 2 ];  //上一页页面实例
    // let path=prevPage.route;
    // let params={
    // 	country:this.country,
    // 	province:this.province,
    // 	city:this.city,
    // 	name:this.name
    // }
    // #ifdef H5
    window.addEventListener('message', this.receiveMessage, true);
    // #endif
  },
  methods: {
    receiveMessage(event) {
      console.log('postMessageFun', event);
      var that = this;
      if (event.data.data) {
        var msg = event.data.data.arg;
				console.log('msg', msg)
        if (!!msg.status && msg.status == 'sure') {
          let times = new Date();
          let year = times.getFullYear();
          let month =
            times.getMonth() < 9
              ? '0' + (times.getMonth() + 1)
              : times.getMonth() + 1;
          let day =
            times.getDate() < 9 ? '0' + times.getDate() : times.getDate();
          let hour =
            times.getHours() < 9 ? '0' + times.getHours() : times.getHours();
          let min =
            times.getMinutes() < 9
              ? '0' + times.getMinutes()
              : times.getMinutes();
          let locationsTime =
            year + '-' + month + '-' + day + ' ' + hour + ':' + min;
          localStorage.setItem('address', msg.address);
          localStorage.setItem('lat', msg.lat);
          localStorage.setItem('lng', msg.lng);
          localStorage.setItem('storeLocation', msg);
          window.removeEventListener('message', this.receiveMessage, true);
          localStorage.setItem('times', locationsTime);
          localStorage.setItem('retailStoreLocation', msg);
          that.$router.go(-1);
        } else if (!!msg.status && msg.status == 'cancel') {
          window.removeEventListener('message', this.receiveMessage, true);
          // that.$Router.navigateBack();
          that.$router.go(-1);
        }
      }
    },
    postMessageFun(data) {
      console.log('postMessageFun', data);
      var msg = data.detail.data;
      if (msg[0].status == 'sure') {
        let times = new Date();
        let year = times.getFullYear();
        let month =
          times.getMonth() < 9
            ? '0' + (times.getMonth() + 1)
            : times.getMonth() + 1;
        let day = times.getDate() < 9 ? '0' + times.getDate() : times.getDate();
        let hour =
          times.getHours() < 9 ? '0' + times.getHours() : times.getHours();
        let min =
          times.getMinutes() < 9
            ? '0' + times.getMinutes()
            : times.getMinutes();
        let locationsTime =
          year + '-' + month + '-' + day + ' ' + hour + ':' + min;
        localStorage.setItem('lat', msg[0].lat);
        localStorage.setItem('lng', msg[0].lng);
        localStorage.setItem('address', msg[0].address);
        // this.$Router.navigateBack();
				this.$router.go(-1)
      } else if (msg[0].status == 'cancel') {
        // this.$Router.navigateBack();
				this.$router.go(-1)
      }
    }
  }
}
</script>

<style></style>
